import React from "react"
import Layout from "../../../components/sermonLayoutElMirage"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/Az2TBb_P-8s">
    <SEO title="Growth and Goals - Hebrews" />
  </Layout>
)

export default SermonPost
